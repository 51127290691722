import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Badge from './Badge'
import styles from './NewsCard.module.sass'

const NewsCard = ({
  badgeText,
  badgeImg,
  category,
  title,
  description,
  url
}) => {
  return (
    <div className={styles.card}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Badge
          text={badgeText}
          icon={badgeImg || require('images/abstract.png')}
        />
        <p className={styles.category}>
          {category === 'abstracts' ? 'abstract' : category}
        </p>
      </div>
      <Link to={'/article/' + url}>
        <p
          dangerouslySetInnerHTML={{ __html: title }}
          className={styles.title}
        />
      </Link>
      <p className={styles.description}>
        {description}
        {url && (
          <Link className={styles.readmore} to={'/article/' + url}>
            read more
          </Link>
        )}
      </p>
    </div>
  )
}

NewsCard.propTypes = {
  badgeText: PropTypes.string,
  badgeImg: PropTypes.string,
  category: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  url: PropTypes.string
}

export default NewsCard
