import { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select, { createFilter } from 'react-select'
import PropTypes from 'prop-types'
import { groupBy, map, orderBy, sumBy, floor, concat, get } from 'lodash'
import moment from 'moment'

import { actions } from './CMETrackerReducer'
import * as creditSelector from './CMETrackerSelector'
import styles from './CreditEarn.module.sass'

import { downloadCsvFromData } from 'utils/utils'
import { onMenuOpen } from 'utils'
import {
  DropdownIndicator,
  IndicatorSeparator,
  SingleValue
} from 'components/Filter'

const { getCertList, getLoading } = creditSelector

export const style_select = {
  singleValue: base => ({
    ...base,
    fontSize: '16px',
    lineHeight: '18px',
    color: '#747474'
  }),
  valueContainer: base => ({
    ...base,
    fontSize: '14px',
    padding: '2px 0 2px 13px'
  }),
  menu: base => ({
    ...base,
    fontSize: '14px',
    color: '#747474',
    marginTop: 0,
    borderRadius: '0 0 3px 3px',
    width: '122px'
  }),
  menuList: base => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
    height: '155px'
  }),
  control: base => ({
    ...base,
    cursor: 'pointer',
    minWidth: '81px',
    width: '122px',
    background:
      'transparent linear-gradient(180deg, #FFFFFF 0%, #F3F3F3 100%) 0% 0% no-repeat padding-box',
    border: '1px solid #C7C7C7',
    borderRadius: '19px',
    'border:hover': '1px solid 009FE3'
  }),
  placeholder: base => ({
    ...base,
    color: '#747474',
    marginLeft: '5px'
  }),
  indicatorContainer: base => ({
    ...base,
    padding: '8px 13px 8px 8px'
  })
}

const CreditEarn = ({ shouldLoadData, nemlToken, onSelectYear }) => {
  const currentYear = moment().format('YYYY')
  const dispatch = useDispatch()
  const [selectedYear, setSelectedYear] = useState(currentYear)
  const [yearList, setYearList] = useState([])
  const [certListByYear, setCertListByYear] = useState([])
  const certList = useSelector(getCertList)
  const isCertListLoading = useSelector(getLoading)

  useEffect(() => {
    if (!nemlToken || !shouldLoadData) {
      return
    }
    dispatch(actions.requestCertList())
    dispatch(actions.requestInProgressActivities())
  }, [dispatch, nemlToken, shouldLoadData])

  useEffect(() => {
    if (!shouldLoadData) return
    dispatch(actions.requestStateBoardList())
  }, [dispatch, shouldLoadData])

  useEffect(() => {
    const certificatesByYear = groupBy(certList, res => {
      return moment(res.created * 1000).format('YYYY')
    })
    const certYear = Object.keys(certificatesByYear)
    const descYear = orderBy(certYear, obj => parseInt(obj, 10), ['desc'])
    const descCertYearList = map(descYear, res => {
      return {
        label: res,
        value: res
      }
    })
    const certificatesByYearIncludeAll = {
      ...certificatesByYear,
      all: certList
    }
    setCertListByYear(certificatesByYearIncludeAll)
    setYearList(
      concat(descCertYearList, [
        {
          label: 'All',
          value: 'all'
        }
      ])
    )
    setSelectedYear(descCertYearList[0] || yearList[0])
    onSelectYear(descCertYearList[0] || yearList[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [certList])

  const totalCreditByYear = useCallback(() => {
    if (selectedYear && certListByYear[selectedYear.value]) {
      return sumBy(certListByYear[selectedYear.value], res =>
        floor(res.credits, 3)
      )
    }
    return 0
  }, [selectedYear, certListByYear])

  const downloadCSV = () => {
    let csvHeader = [
      'Date Completed',
      'Activity Title',
      'Activity ID',
      'Credit Amount',
      'Credit Type',
      'Certificate URL'
    ]
    let data = map(certListByYear[selectedYear.value], (res, key) => {
      return {
        'Date Completed': moment(res.created * 1000).format('MMMM D, YYYY'),
        'Activity Title': res.activity_title,
        'Activity ID': res.program,
        'Credit Amount': res.credits,
        'Credit Type': res.credit_type,
        'Certificate URL': res.certificate_uri,
        key: key
      }
    })
    let currentDate = moment().format('YYYY_MM_DD_HH_mm')
    let fileName = `AnswersInCME_Credit_Transcript_${currentDate}`
    downloadCsvFromData(csvHeader, data, fileName)
  }

  return (
    <div className={styles.content}>
      <h2>Credit History</h2>
      <table cellSpacing="0" cellPadding="0" className={styles.table}>
        <thead>
          <tr>
            <th>Year</th>
            <th colSpan="2">You Earned</th>
          </tr>
        </thead>
        <tbody>
          <tr style={{ height: '70px' }}>
            <td
              data-testid={'year'}
              className={styles.colyear}
              style={{ padding: '15px 20px', width: '206px' }}
            >
              <Select
                // dataTestId={'year'}
                className={styles.dropdown}
                classNamePrefix={'dropdown'}
                // placeholder="Select year"
                value={selectedYear}
                options={yearList}
                onChange={res => {
                  setSelectedYear(res)
                  onSelectYear(res)
                }}
                onMenuOpen={() => onMenuOpen('dropdown')}
                filterOption={createFilter({ matchFrom: 'start' })}
                isSearchable={true}
                isClearable={false}
                isLoading={isCertListLoading}
                components={{
                  DropdownIndicator,
                  IndicatorSeparator,
                  SingleValue
                }}
                styles={{
                  ...style_select,
                  indicatorsContainer: base => ({
                    ...base,
                    paddingRight: '5px'
                  })
                }}
              />
            </td>
            <td>
              <p className={styles.creditText}>
                {totalCreditByYear()
                  ? totalCreditByYear() + ' CME Credits'
                  : '-'}
              </p>
            </td>
            <td>
              <button
                data-testid="download"
                alt="download"
                className={styles.downloadButton}
                disabled={
                  !selectedYear ||
                  get(certListByYear[selectedYear.value], 'length', 0) === 0
                }
                onClick={downloadCSV}
                onKeyDown={e => {
                  if (e.key === 'Enter' || e.key === ' ') return downloadCSV
                }}
              >
                Download
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

CreditEarn.propTypes = {
  /**
   * Set NEML token
   */
  nemlToken: PropTypes.object,
  /**
   * Check if should load data
   */
  shouldLoadData: PropTypes.bool,
  /**
   * Return selected year
   */
  onSelectYear: PropTypes.func
}

export default CreditEarn
