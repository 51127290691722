import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Select, MenuItem, InputLabel, FormControl } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { actions } from 'modules/home/HomeReducer'
import { getSpecialtyInput, getSearchPanel } from 'modules/home/HomeSelector'
import { actions as authActions } from 'modules/auth/AuthReducer'
import { getIsLogin, getProfile } from 'modules/auth/AuthSelector'
import { getOptinSpecialtyLIMAtoCONTENT } from 'modules/optin/OptinSelector'
import { getProfileInfo } from 'utils'

import HeaderLink from './HeaderLink'
import HeaderMenu from 'components/HeaderMenu'
import SearchBar from './SearchBar'
import styles from './Header.module.sass'
import config from 'site.config'

const { openLoginModal } = authActions

const Menu = ({
  route,
  iconSrc,
  title,
  alt,
  width,
  requireAuth,
  onClick,
  menuOpen,
  exceptMobile,
  onlyMobile
}) => {
  const pathname = window.location.pathname
  const isActive = pathname === route

  return (
    <div
      role="button"
      tabIndex={0}
      alt={alt}
      className={classNames(styles.menuItem, {
        [styles.active]: isActive,
        [styles.menuOpen]: menuOpen,
        [styles.exceptMobile]: exceptMobile,
        [styles.onlyMobile]: onlyMobile
      })}
      style={{ width }}
      onClick={() => onClick(route, requireAuth)}
      onKeyDown={e => {
        if (e.key === 'Enter' || e.key === ' ') onClick(route, requireAuth)
      }}
    >
      <img
        src={require('images/' + iconSrc)}
        alt={alt}
        height={20}
        className={classNames(styles.btnimg, {
          [styles.active]: isActive,
          [styles.menuOpen]: menuOpen
        })}
      />
      {title && <label aria-label={alt}>{title}</label>}
    </div>
  )
}

Menu.propTypes = {
  route: PropTypes.string,
  iconSrc: PropTypes.string,
  title: PropTypes.string,
  alt: PropTypes.string,
  width: PropTypes.string,
  requireAuth: PropTypes.bool,
  menuOpen: PropTypes.bool,
  exceptMobile: PropTypes.bool,
  onlyMobile: PropTypes.bool,
  onClick: PropTypes.func
}

const DropdownIcon = props => <ExpandMoreIcon {...props} />

const Header = props => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [menuOpen, setMenuOpen] = useState(false)
  const searchOpen = useSelector(getSearchPanel)

  // Specialty
  const specialtyInput = useSelector(getSpecialtyInput)
  const specialtyOptions = useSelector(getOptinSpecialtyLIMAtoCONTENT)

  const isLoginUser = useSelector(getIsLogin)
  const profile = useSelector(getProfile)

  useEffect(() => {
    if (isLoginUser && profile) {
      const { specialtyId } = getProfileInfo(profile)
      const newValue = specialtyOptions.find(f => f.value === specialtyId)
      if (specialtyId && newValue) {
        dispatch({ type: 'SET_SPECIALTY_INPUT', payload: newValue })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isLoginUser, profile, specialtyOptions?.length])

  useEffect(() => {
    setMenuOpen(props.menuOpen === true)
  }, [props.menuOpen])

  const checkValidateBeforeRoute = (route, requireAuth) => {
    if (requireAuth && !isLoginUser) {
      dispatch(openLoginModal({ route }))
      return
    }
    history.push(route)
  }

  const toggleMenu = () => {
    setMenuOpen(!menuOpen)
  }

  const toggleSearch = () => {
    dispatch(actions.setSearchPanel(!searchOpen))
  }

  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth)
    window.addEventListener('resize', handleWindowResize)
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  return (
    <header className={styles.bg}>
      <div id="header" className={styles.box}>
        <div className={styles.dleft}>
          <HeaderLink
            styles={styles}
            logo={config.logo}
            alt={config.siteName}
            tagline={config.logo.tagline}
          />
          {width >= 1040 && <SearchBar />}
        </div>
        <div className={styles.dcenter}>
          {(!searchOpen || width >= 1040) && (
            <FormControl
              sx={{
                width: '100%',
                '& .Mui-focused': {
                  color: '#fff !important'
                },
                '& label': {
                  marginTop: '-8px'
                },
                '& .MuiInputLabel-root': {
                  transform: 'translate(14px, -1px) scale(0.75)',
                  '-webkit-transform': 'translate(14px, -1px) scale(0.75)'
                }
              }}
            >
              <InputLabel
                id="ddSpecialty-label"
                sx={{ color: '#fff', fontFamily: '"PT Sans"' }}
              >
                Area of Medicine
              </InputLabel>
              <Select
                notched
                label="Area of Medicine"
                labelId="ddSpecialty-label"
                id="ddSpecialty"
                value={specialtyOptions?.length ? specialtyInput.value : ''}
                IconComponent={DropdownIcon}
                sx={{
                  width: '100%',
                  fontFamily: '"PT Sans"',
                  '& .MuiInputLabel-root': {
                    color: '#fff',
                    borderColor: '#fff',
                    fontFamily: '"PT Sans"',
                    fontSize: '16px',
                    lineHeight: '22px'
                  },
                  '& .MuiInputBase-input': {
                    color: '#fff',
                    fontFamily: '"PT Sans"'
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderRadius: '30px',
                    border: 'solid 1.5px #fff !important',
                    ' legend': {
                      fontSize: '12px',
                      lineHeight: '18px',
                      width: '100px'
                    }
                  },
                  '.MuiSvgIcon-root': {
                    color: '#fff'
                  }
                }}
                MenuProps={{ PaperProps: { sx: { maxHeight: 460 } } }}
                size="small"
                onChange={e => {
                  const newValue = e.target.value
                  const selectedOption = specialtyOptions.find(
                    f => f.value === newValue
                  )
                  if (specialtyInput && selectedOption) {
                    dispatch({
                      type: 'SET_SPECIALTY_INPUT',
                      payload: selectedOption
                    })
                  }
                }}
              >
                {specialtyOptions?.map(option => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    sx={{
                      width: '100%',
                      fontSize: '16px',
                      lineHeight: '22px',
                      fontFamily: '"PT Sans"'
                    }}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {searchOpen && width < 1040 && <SearchBar />}
        </div>
        <div className={styles.dright}>
          <Menu
            route="/cme-tracker"
            iconSrc="icon-credits.png"
            title="CME Tracker"
            alt="CME Tracker"
            requireAuth
            onClick={() => checkValidateBeforeRoute('/cme-tracker', true)}
            width={width < 1040 ? '90px' : 'unset'}
          />
          <Menu
            iconSrc="icon-search.png"
            onClick={toggleSearch}
            alt="search"
            onlyMobile
          />
          <Menu
            iconSrc="icon-menu.png"
            menuOpen={menuOpen}
            onClick={toggleMenu}
            alt="menu"
          />
          <HeaderMenu open={menuOpen} closeMenu={() => setMenuOpen(false)} />
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  menuOpen: PropTypes.any
}

export default Header
