import { useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import Header from 'components/Header'
import Rail from 'components/Rail'
import RailLeft from 'components/RailLeft'
import Footer from 'components/Footer'
import PageLoader from 'components/PageLoader'
import SearchResult from 'components/SearchResult'
import { getSearchTerm } from 'modules/home/HomeSelector'

import styles from './FormLayout.module.sass'
import stylesRD from 'components/RailDual.module.sass'

const FormLayout = ({ children, loading }) => {
  const crypto = window.crypto || window.msCrypto
  let random = new Uint32Array(1)

  const searchTerm = useSelector(getSearchTerm)
  const [menuOpen, setMenuOpen] = useState(false)

  return (
    <>
      <div className={styles.box}>
        <Header menuOpen={menuOpen} />
        <div className={styles.gap}></div>
        <div
          role="button"
          tabIndex={0}
          data-testid="result"
          className={styles.content}
          style={{ cursor: 'default' }}
          onClick={() => setMenuOpen(crypto?.getRandomValues(random)[0])}
          onKeyDown={e => {
            if (e.key === 'Enter' || e.key === ' ')
              setMenuOpen(crypto?.getRandomValues(random)[0])
          }}
        >
          <RailLeft />
          {!searchTerm?.length && <Rail styles={stylesRD}>{children}</Rail>}
          {searchTerm?.length > 0 && (
            <Rail styles={stylesRD}>
              <SearchResult />
            </Rail>
          )}
        </div>
        {loading && <PageLoader />}
      </div>
      <Footer />
    </>
  )
}

FormLayout.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool
}

export default FormLayout
