import { useHistory } from 'react-router-dom'
import Svg from 'react-inlinesvg'
import styles from './BackButton.module.sass'

const BackButton = () => {
  const history = useHistory()

  return (
    <button
      className={styles.back}
      onClick={() => history.goBack()}
      onKeyDown={e => {
        if (e.key === 'Enter' || e.key === ' ') history.goBack()
      }}
    >
      <p className={styles.backTxt}>
        <Svg
          src="/img/chevron-left.svg"
          height={12}
          width={7}
          fill={'#979797'}
          style={{ marginRight: '4.3px' }}
        />
        Back
      </p>
    </button>
  )
}

export default BackButton
