import { takeEvery, all, put, call, select } from 'redux-saga/effects'
import { actions } from './CMETrackerReducer'
// import { actions as notificationActions } from 'modules/notification/NotificationReducer'
import * as authSelector from 'modules/auth/AuthSelector'
import config from 'site.config.json'

const {
  configuration: { envVars, suggestedPrograms }
} = config

export function* getCertList(services) {
  try {
    const apiService = services('APIService')
    const authToken = yield select(authSelector.getNemlToken)
    const profile = yield select(authSelector.getProfileInfo)
    const { partyId } = profile

    const baseUrl =
      envVars?.REACT_APP_NEML_API || process.env.REACT_APP_NEML_API
    const param = '?PartyID=' + partyId
    const url = baseUrl + '/parcel/v1/certificates/list' + param
    const additionalHeader = {
      Authorization: `Bearer ${authToken.access}`,
      'X-NEML-AuthType': 'phoenixv2'
    }

    const result = yield call(
      [apiService, 'getWithHeaders'],
      url,
      {},
      additionalHeader
    )
    yield put(actions.requestCertListSuccess(result))
  } catch (e) {
    yield put(actions.requestCertListFail(e))
  }
}

export function* getSuggestedPrograms(services, { payload }) {
  try {
    const cookieService = services('CookieService')
    const apiService = services('APIService')

    const profile = yield select(authSelector.getProfileInfo)

    const query = {
      ...suggestedPrograms.query,
      page: payload.currentPage
    }
    if (profile.specialty) {
      query.searchTerms.must['tags.specialties'][0] =
        payload.specialty || profile.specialty
    }

    const expiration_date = Math.floor(Date.now() / 1000)
    query.searchTerms.must.range[
      'extra_fields.expiration_date'
    ].gte = expiration_date

    let baseUrl = envVars?.REACT_APP_PHNX_API ?? process.env.REACT_APP_PHNX_API
    const url = baseUrl + '/v2/fb/search/article/activity'
    const authToken = JSON.parse(cookieService.get('authToken'))
    const additionalHeader = {
      Authorization: `Bearer ${authToken.access}`
    }

    const result = yield call(
      [apiService, 'postWithHeaders'],
      url,
      query,
      additionalHeader
    )
    yield put(actions.requestSuggestedProgramsSuccess(result))
  } catch (e) {
    yield put(actions.requestSuggestedProgramsFail(e))
  }
}

export function* getInProgressActivities(services) {
  try {
    const apiService = services('APIService')
    const authToken = yield select(authSelector.getNemlToken)
    const profile = yield select(authSelector.getProfileInfo)
    const { partyEmailId, partyId } = profile

    const baseUrl =
      envVars?.REACT_APP_NEML_API || process.env.REACT_APP_NEML_API
    const domainId = envVars?.REACT_APP_NEML_DOMAINID
    const param = '?domainId=' + domainId
    const url = baseUrl + '/persist/v1/list/history' + param
    const additionalHeader = {
      Authorization: `Bearer ${authToken.access}`,
      'x-neml-authtype': 'phoenixv2'
    }

    const result = yield call(
      [apiService, 'getWithHeaders'],
      url,
      {},
      additionalHeader
    )
    yield put(
      actions.requestInProgressActivitiesSuccess({
        result,
        MemberID: partyId,
        EmailID: partyEmailId
      })
    )
  } catch (e) {
    yield put(actions.requestInProgressActivitiesFail(e))
  }
}

export default function* watchCMETracker(services) {
  yield all([
    takeEvery(actions.requestCertList, getCertList, services),
    takeEvery(actions.requestSuggestedPrograms, getSuggestedPrograms, services),
    takeEvery(
      actions.requestInProgressActivities,
      getInProgressActivities,
      services
    )
  ])
}
