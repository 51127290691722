import { LazyLoadImage } from 'react-lazy-load-image-component'

import DualLayout from 'layouts/DualLayout'
import BackButton from 'components/BackButton'

import styles from './AboutUs.module.sass'

const AboutUs = () => {
  return (
    <DualLayout loading={false} disableLottie={true}>
      <div className={styles.box}>
        <h1>ABOUT US</h1>
        <p>
          At Answers in CME (AiCME), we are committed to enhancing the
          experience and quality of education across therapeutic areas through
          pioneering efforts in microburst learning. By delivering succinct,
          short-format activities that increase clinician competence, our CME
          supports practice improvements that help patients live longer,
          healthier, and better-quality lives.
        </p>
        <p>
          AiCME is pleased to hold accreditations from both the Accreditation
          Council for Continuing Medical Education (ACCME) and the European
          Board for Accreditation of Continuing Education for Health
          Professionals (EBAC). Additionally, we are a proud member of the Good
          CME Practice Group (gCMEp).
        </p>
        <p className={styles.hideMobile}>
          <br />
          <br />
        </p>
        <div className={styles.logos}>
          <a href="https://accme.org" target="_blank" rel="noreferrer">
            <LazyLoadImage
              src="https://c.answersincme.com/corporate/images/accme.png"
              effect="opacity"
              alt="accme-logo"
              className={styles.img}
            />
          </a>
          <a href="https://www.ebac-cme.org" target="_blank" rel="noreferrer">
            <LazyLoadImage
              src="https://c.answersincme.com/corporate/images/ebac.png"
              effect="opacity"
              alt="ebac-logo"
              className={styles.img}
            />
          </a>
          <a href="https://gcmep.org" target="_blank" rel="noreferrer">
            <LazyLoadImage
              src="https://c.answersincme.com/corporate/images/gcmep.png"
              effect="opacity"
              alt="gcmep-logo"
              className={styles.img}
            />
          </a>
        </div>

        <BackButton style={{ marginTop: '40px' }} />
      </div>
    </DualLayout>
  )
}

export default AboutUs
