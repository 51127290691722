import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import LoadingDots from 'components/LoadingDots'
import styles from './Curriculum.module.sass'
import {
  getLoadingCurricula,
  getCurricula,
  getMediaUrlPSource
} from 'modules/home/HomeSelector'
import {
  getIsLogin,
  getProfile,
  getHasPartyId
} from 'modules/auth/AuthSelector'

export const getProfileInfo = profile => {
  const partyId = get(profile, 'info.user.partyId', '')
  const partyEmailId = get(profile, 'info.user.partyEmailId', '')
  const professionId = get(profile, 'info.user.professionId', '')
  const specialtyId = get(profile, 'info.user.specialtyId', '')
  return { partyId, partyEmailId, professionId, specialtyId }
}

const Curriculum = props => {
  const loading = useSelector(getLoadingCurricula)
  const curricula = useSelector(getCurricula)

  const mediaUrlPSource = useSelector(getMediaUrlPSource)
  const hasPartyId = useSelector(getHasPartyId)
  const isLoggedIn = useSelector(getIsLogin)
  const profile = useSelector(getProfile)
  let [urlParams, setUrlParams] = useState(
    `?SpecialtyID=&AudienceID=aicme&PromoCode=796${String(mediaUrlPSource)}`
  )
  useEffect(() => {
    if (isLoggedIn && profile) {
      const {
        partyId,
        partyEmailId,
        professionId,
        specialtyId
      } = getProfileInfo(profile)
      setUrlParams(
        `?MemberID=${partyId}&EmailID=${partyEmailId}&ProfessionID=${professionId}&SpecialtyID=${specialtyId}&AudienceID=aicme&PromoCode=796`
      )
    } else if (hasPartyId && profile) {
      const { partyId } = getProfileInfo(profile)
      setUrlParams(
        `?MemberID=${partyId}&AudienceID=aicme&PromoCode=796${String(
          mediaUrlPSource
        )}`
      )
    }
  }, [isLoggedIn, hasPartyId, profile, mediaUrlPSource])

  return (
    <div className={styles.container}>
      <h2 className={styles.h2}>Curricula</h2>
      {!loading &&
        curricula?.map(({ title, url }) => (
          <div key={title} className={styles.span}>
            <a href={url + urlParams} target="_blank" rel="noreferrer">
              <p className={styles.link} style={{ fontWeight: 'bold' }}>
                {title}
              </p>
            </a>
          </div>
        ))}
      {loading && <LoadingDots />}
    </div>
  )
}

Curriculum.propTypes = {}

export default Curriculum
