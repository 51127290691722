import FormLayout from 'layouts/FormLayout'
import BackButton from 'components/BackButton'

import './style.css'
import styles from './SignUp.module.sass'
import config from 'site.config'

const { sections } = config

const SignUpSuccess = () => {
  const { email } = sections.privacy

  return (
    <FormLayout loading={false} disableLottie={true}>
      <div className={styles.box}>
        <div className={styles.modal}>
          <p>
            Thank you for your interest in <strong>Answers In CME</strong>.
          </p>
          <p>
            We have received your registration request and will contact you
            shortly.
          </p>
          <p>
            For any further assistance, do not hesitate to{' '}
            <a href={'mailto:' + email}>contact us</a>.
          </p>
        </div>
        <BackButton />
      </div>
    </FormLayout>
  )
}

export default SignUpSuccess
