import styles from './AuthWithEmail.module.sass'

export const variables = () => {
  const form = document.querySelector('form')
  const email = document.getElementById('email')?.value
  const password = document.getElementById('password')?.value

  return { form, email, password }
}

export const setError = ele => {
  ele.classList.add(styles.inputF)
  ele.classList.remove(styles.inputG)
}

export const setGood = ele => {
  ele.classList.add(styles.inputG)
  ele.classList.remove(styles.inputF)
}

export const resetElement = ele => {
  ele.value = ''
  ele.classList.remove(styles.inputG)
  ele.classList.remove(styles.inputF)
}

export const checkField = (elementId, fieldname, errorFunc) => {
  const ele = document.getElementById(elementId)
  if (!ele.checkValidity()) {
    errorFunc(`Your ${fieldname} is required.`)
    setError(ele)
  } else {
    errorFunc('')
    setGood(ele)
  }
}

export const resetForm = () => {
  resetElement(document.getElementById('email'))
  if (document.getElementById('password'))
    resetElement(document.getElementById('password'))
}

export const checkError = (setErrorEmail, setErrorPassword) => {
  if (setErrorEmail && !document.getElementById('email').checkValidity()) {
    setErrorEmail('Your Email is required.')
    setError(document.getElementById('email'))
  }
  if (
    setErrorPassword &&
    !document.getElementById('password').checkValidity()
  ) {
    setErrorPassword('Your Password is required.')
    setError(document.getElementById('password'))
  }
}
