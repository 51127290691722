import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import EventCard from 'components/EventCard'
import LoadingDots from 'components/LoadingDots'
import styles from './Curriculum.module.sass'
import {
  getLoadingEvents,
  getEvents,
  getMediaUrlPSource
} from 'modules/home/HomeSelector'
import {
  getIsLogin,
  getProfile,
  getHasPartyId
} from 'modules/auth/AuthSelector'

export const getProfileInfo = profile => {
  const partyId = get(profile, 'info.user.partyId', '')
  const partyEmailId = get(profile, 'info.user.partyEmailId', '')
  const professionId = get(profile, 'info.user.professionId', '')
  const specialtyId = get(profile, 'info.user.specialtyId', '')
  return { partyId, partyEmailId, professionId, specialtyId }
}

const expire_delay = 4 * 60 * 60 * 1000 // Miliseconds in 2 hours
const exceed_1y = 365 * 24 * 60 * 60 * 1000 // Miliseconds in a year

const Events = () => {
  // const dispatch = useDispatch()
  // const specialtyInput = useSelector(getSpecialtyInput)
  const loading = useSelector(getLoadingEvents)
  const events = useSelector(getEvents)

  const [upcomingEvents, setUpcomingEvents] = useState([])
  const [pastEvents, setPastEvents] = useState([])
  useEffect(() => {
    if (!events) return
    // Upcoming Events
    try {
      const upcoming = events
        .filter(
          f =>
            new Date().getTime() - new Date(f.dates[0]).getTime() < expire_delay
        )
        .sort(
          (a, b) =>
            new Date(a.dates[0]).getTime() - new Date(b.dates[0]).getTime()
        )
      setUpcomingEvents(upcoming)
    } catch (e) {
      setUpcomingEvents([])
    }

    // Past Events less than a year ago
    try {
      const past = events
        .filter(
          f =>
            new Date().getTime() - new Date(f.dates[0]).getTime() >=
              expire_delay &&
            new Date().getTime() - new Date(f.dates[0]).getTime() < exceed_1y
        )
        .sort(
          (a, b) =>
            new Date(b.dates[0]).getTime() - new Date(a.dates[0]).getTime()
        )
      setPastEvents(past)
    } catch (e) {
      setPastEvents([])
    }
  }, [events])

  const mediaUrlPSource = useSelector(getMediaUrlPSource)
  const hasPartyId = useSelector(getHasPartyId)
  const isLoggedIn = useSelector(getIsLogin)
  const profile = useSelector(getProfile)
  let [urlParams, setUrlParams] = useState(
    `?SpecialtyID=&AudienceID=aicme&PromoCode=796${String(mediaUrlPSource)}`
  )
  useEffect(() => {
    if (isLoggedIn && profile) {
      const {
        partyId,
        partyEmailId,
        professionId,
        specialtyId
      } = getProfileInfo(profile)
      setUrlParams(
        `?MemberID=${partyId}&EmailID=${partyEmailId}&ProfessionID=${professionId}&SpecialtyID=${specialtyId}&AudienceID=aicme&PromoCode=796`
      )
    } else if (hasPartyId && profile) {
      const { partyId } = getProfileInfo(profile)
      setUrlParams(
        `?MemberID=${partyId}&AudienceID=aicme&PromoCode=796${String(
          mediaUrlPSource
        )}`
      )
    }
  }, [isLoggedIn, hasPartyId, profile, mediaUrlPSource])

  return (
    <div className={styles.container}>
      <h2 className={styles.h2}>Upcoming Live Events</h2>
      {!loading &&
        upcomingEvents?.map(
          ({ title, url, dates, format, location, credit, presenters }) => (
            <EventCard
              key={url}
              title={title}
              url={url + urlParams}
              dates={dates}
              format={format}
              location={location}
              credit={credit}
              presenters={presenters}
            />
          )
        )}
      {!loading && upcomingEvents.length < 1 && (
        <p style={{ marginBottom: '30px' }}>No upcoming live events found.</p>
      )}
      {loading && <LoadingDots />}

      <h2 className={styles.h2}>Past Live Events</h2>
      {!loading &&
        pastEvents?.map(
          ({ title, url, dates, format, location, credit, presenters }) => (
            <EventCard
              key={url}
              title={title}
              url={url + urlParams}
              dates={dates}
              format={format}
              location={location}
              credit={credit}
              presenters={presenters}
            />
          )
        )}
      {!loading && pastEvents.length < 1 && (
        <p style={{ marginBottom: '30px' }}>No past live events found.</p>
      )}
      {loading && <LoadingDots />}
    </div>
  )
}

Events.propTypes = {}

export default Events
