import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import { UniversalRegistration } from '@psl-ui/dougallgpt-components'
import '@psl-ui/dougallgpt-components/dist/style.css'
import './style.css'
import FormLayout from 'layouts/FormLayout'
import BackButton from 'components/BackButton'
import { getLoading } from 'modules/auth/AuthSelector'
import { getIsProcessingRegister } from 'modules/user/UserSelector'
import Session from 'services/SessionService'
import CookieService from 'services/CookieService'
import styles from './SignUp.module.sass'
import config from 'site.config'

const SessionService = new Session()
const { sections } = config

const SignUp = () => {
  const history = useHistory()
  const [open, setOpen] = useState(false)
  const isProcessingRegistration = useSelector(getIsProcessingRegister)
  const isOtpLoading = useSelector(getLoading)

  const { email } = sections.privacy
  const { title, formURL } = sections.signup

  const [authToken, setAuthToken] = useState(
    CookieService().get('authToken')
      ? JSON.parse(CookieService().get('authToken'))
      : false
  )
  useEffect(() => {
    let timer = setInterval(() => {
      if (!authToken && CookieService().get('authToken'))
        setAuthToken(JSON.parse(CookieService().get('authToken')))
    }, 3000)
    return () => {
      clearTimeout(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Language
  let languageCode = navigator.language?.split('-')[0] || 'en'
  if (navigator.language === 'en-gb') languageCode = 'en-gb'
  if (navigator.language === 'pt-BR') languageCode = 'pt-BR'
  if (
    !['en', 'en-gb', 'fr', 'it', 'de', 'ja', 'zh', 'pt-BR'].includes(
      languageCode
    )
  )
    languageCode = 'en'

  // URF handlers
  const handleFormSubmitDone = () => {
    history.push('/registration-thanks')
  }

  const handleFormSubmitFail = () => {
    setOpen(true)
    // dispatch(
    //   notificationEnqueue({
    //     message: t('registrationFail'),
    //     type: 'error'
    //   })
    // )
  }

  const handleModalClose = () => {
    setOpen(false)
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4
  }

  return (
    <FormLayout
      loading={isProcessingRegistration || isOtpLoading}
      disableLottie={true}
    >
      <div className={styles.box}>
        <h3>{title}</h3>
        <p>
          Unlock exclusive access to premier educational programs and
          opportunities tailored for your practice. By signing up, you&apos;ll
          receive:
        </p>
        <ul>
          <li>CME Tracker to view and download your CME credits</li>
          <li>
            Personalized newsletters with offers to enhance your knowledge and
            skills
          </li>
          <li>
            Invitations to participate in cutting-edge education that impacts
            patient care
          </li>
          <li>Opportunities to engage with industry experts and peers</li>
        </ul>
        <div className={styles.form}>
          {authToken.access && (
            <>
              <UniversalRegistration
                config={{
                  accessToken: authToken.access,
                  formURL,
                  brandId: parseInt(process.env.REACT_APP_AUTH_BRANDID),
                  brandCode: process.env.REACT_APP_AUTH_BRANDNAME,
                  siteId: parseInt(process.env.REACT_APP_PHNX_SITEID),
                  languageCode,
                  interactionId: SessionService.getFromCache(
                    'interactionId',
                    ''
                  ),
                  deviceId: SessionService.getFromCache('id_key', ''),
                  lastPageVisited: SessionService.getFromCache(
                    'lastAction',
                    '/'
                  ),
                  profileId: process.env.REACT_APP_URF_PROFILEID,
                  recaptchaKey: process.env.REACT_APP_URF_RECAPTCHA_KEY
                }}
                onSubmitDone={handleFormSubmitDone}
                onSubmitFail={handleFormSubmitFail}
              />
              <p style={{ color: '#444', fontSize: '14px', marginTop: '4px' }}>
                <span style={{ color: 'red' }}>
                  <b>*</b>
                </span>{' '}
                Required
              </p>
            </>
          )}
        </div>
        <Modal
          open={open}
          onClose={handleModalClose}
          aria-labelledby="modal-otp"
        >
          <Box sx={style} className={styles.modal}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img src="/img/logo.png" alt="logo" height="60px" />
            </div>
            <hr />
            <div>
              <p>There was an error processing your request.</p>
              <p>
                For any further assistance, do not hesitate to{' '}
                <a href={'mailto:' + email}>contact us</a>.
              </p>
            </div>
          </Box>
        </Modal>
        <BackButton />
      </div>
    </FormLayout>
  )
}

export default SignUp
