import { useEffect, useState, useRef, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import Badge from './Badge'
import { actions } from 'modules/home/HomeReducer'
import {
  getSpecialtyInput,
  getAd,
  getMediaUrlPSource
} from 'modules/home/HomeSelector'
import { getPartyId, getProfileInfo } from 'modules/auth/AuthSelector'
import styles from './Ad.module.sass'

const AdCard = ({
  type,
  position,
  specialty,
  anchor = false,
  viewid,
  badge = false,
  random
}) => {
  const dispatch = useDispatch()
  const partyId = useSelector(getPartyId)
  const profileInfo = useSelector(getProfileInfo)
  const specialtyInput = useSelector(getSpecialtyInput)
  const mediaUrlPSource = useSelector(getMediaUrlPSource)
  const { data } = useSelector(getAd)
  const [ads, setAds] = useState([])
  const specIdRef = useRef('')
  const partyIdRef = useRef('')

  useEffect(() => {
    if (specialtyInput.value) setAds([])
    let payload = { type, random, specialtyId: specialtyInput.value }
    if (position) payload.position = position

    // only performs ad request when partyId is not empty
    if (partyId !== '') {
      // if it is the trigger from partyId
      if (partyId !== partyIdRef.current) {
        // specIdRef.current would need to get from user profile
        specIdRef.current = profileInfo?.specialtyId || specialtyInput.value
        payload.specialtyId = specIdRef.current
        partyIdRef.current = partyId
        dispatch(actions.requestAd(payload))
      } else if (specIdRef.current !== specialtyInput.value) {
        specIdRef.current = specialtyInput.value
        dispatch(actions.requestAd(payload))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, specialtyInput?.value, partyId])

  useEffect(() => {
    if (!ads.length && data.length) {
      const adFound = data.findLast(ad => ad.random === random)
      if (adFound) setAds([adFound])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.length])

  return (
    // <>
    //   {!loading &&
    ads?.map(
      ({
        type,
        clickUrl,
        mediaUrl,
        logo,
        sourceName,
        topRightText,
        title,
        html
      }) => (
        <Fragment key={title}>
          {anchor && type !== 'html' && (
            <p
              style={{
                textAlign: 'center',
                margin: '0 0 16px',
                lineHeight: '18px'
              }}
            >
              <a
                href="#continue"
                className={styles.ad_anchor}
                id={'previous' + anchor}
              >
                a
              </a>
              <a href={'#continue' + anchor} className={styles.ad_link}>
                Next article
              </a>
            </p>
          )}
          <div key={title}>
            {badge && (
              <div className={styles.cardHeader}>
                {sourceName && <Badge text={sourceName} icon={logo} />}
                {html?.publicationDoctor && (
                  <Badge text={html.publicationDoctor} icon={html.logo} />
                )}
                <p className={styles.category}>
                  {html?.topRightText ?? topRightText}
                </p>
              </div>
            )}
            {type === 'html' && (
              <>
                <a href={clickUrl} target="_blank" rel="noreferrer">
                  <p className={styles.title}>{html?.headline}</p>
                </a>
                <div className={styles.cardBody}>
                  <p className={styles.description}>
                    {html?.teaserText ? html.teaserText + '...' : ''}
                    <a
                      href={clickUrl}
                      target="_blank"
                      rel="noreferrer"
                      className={styles.readmore}
                    >
                      {html.ctaText}
                    </a>
                  </p>
                </div>
              </>
            )}
            {type === 'iframe' && (
              <iframe
                src={mediaUrl + mediaUrlPSource}
                title={title}
                className={styles.iframe}
              ></iframe>
            )}
          </div>
          {anchor && type !== 'html' && (
            <p
              style={{
                textAlign: 'center',
                margin: '15px 0 0',
                lineHeight: '18px'
              }}
            >
              <a
                href={'#previous' + anchor}
                className={styles.ad_anchor}
                id={'continue' + anchor}
              >
                a
              </a>
              <a href={'#previous' + anchor} className={styles.ad_link}>
                Previous article
              </a>
            </p>
          )}
        </Fragment>
      )
    )
    // }
    //   {loading && <LoadingDots />}
    // </>
  )
}

AdCard.propTypes = {
  type: PropTypes.string,
  position: PropTypes.string,
  specialty: PropTypes.number,
  anchor: PropTypes.bool,
  viewid: PropTypes.string,
  badge: PropTypes.bool,
  random: PropTypes.string
}

export default AdCard
