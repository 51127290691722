import { useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import SearchResult from 'components/SearchResult'
import PageLoader from 'components/PageLoader'
import Header from 'components/Header'
import Footer from 'components/Footer'
import BackButton from 'components/BackButton'
import { getSearchTerm } from 'modules/home/HomeSelector'

import styles from './SingleLayout.module.sass'

const crypto = window.crypto || window.msCrypto
let random = new Uint32Array(1)

const SingleLayout = ({ children, isLoading }) => {
  const searchTerm = useSelector(getSearchTerm)
  const [menuOpen, setMenuOpen] = useState(false)

  return (
    <div className={styles.box}>
      <Header menuOpen={menuOpen} />
      <div className={styles.gap}></div>
      {!searchTerm?.length && (
        <>
          <div
            role="button"
            tabIndex={0}
            data-testid="content"
            className={styles.content}
            onClick={() => setMenuOpen(crypto?.getRandomValues(random)[0])}
            onKeyDown={e => {
              if (e.key === 'Enter' || e.key === ' ')
                setMenuOpen(crypto?.getRandomValues(random)[0])
            }}
          >
            {children}
          </div>
          <BackButton />
        </>
      )}
      {searchTerm?.length > 0 && (
        <div
          role="button"
          tabIndex={0}
          data-testid="search"
          className={styles.content}
          onClick={() => setMenuOpen(crypto?.getRandomValues(random)[0])}
          onKeyDown={e => {
            if (e.key === 'Enter' || e.key === ' ')
              setMenuOpen(crypto?.getRandomValues(random)[0])
          }}
        >
          <SearchResult />
        </div>
      )}
      <Footer />
      {isLoading && <PageLoader />}
    </div>
  )
}

SingleLayout.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool
}

export default SingleLayout
