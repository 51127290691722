import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { actions as authActions } from 'modules/auth/AuthReducer'
import { getIsLogin } from 'modules/auth/AuthSelector'
import PropTypes from 'prop-types'
import styles from './HeaderMenu.module.sass'
import cx from 'classnames'
import { getMenuLinksConfig } from 'modules/bootstrap/BootstrapSelector'
import config from 'site.config'

const {
  sections: { menu }
} = config
const { openLoginModal, invalidateToken } = authActions

const HeaderMenu = props => {
  const history = useHistory()
  const dispatch = useDispatch()
  const isLoggedIn = useSelector(getIsLogin)
  const routeTo = (route, e = false) => {
    if (!e || e.key === 'Enter' || e.key === ' ') {
      if (props.closeMenu) props.closeMenu()
      if (route.includes('answersincme.com')) {
        window.open(route, '_self')
        return
      }
      if (route.includes('http')) {
        window.open(route, '_blank')
        return
      }
      window.location.assign(route)
    }
  }

  let [links, setLinks] = useState(menu.links)

  // Menu links array items updated if found in config.json
  const menu_links = useSelector(getMenuLinksConfig)
  useEffect(
    () => setLinks(menu_links?.length ? menu_links : links),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [menu_links]
  )

  const checkValidateBeforeRoute = (route, requireAuth, e = false) => {
    if (!e || e.key === 'Enter' || e.key === ' ') {
      if (props.closeMenu) props.closeMenu()
      if (requireAuth && !isLoggedIn) {
        dispatch(openLoginModal({ route }))
        return
      }
      history.push(route)
    }
  }

  const signout = () => {
    if (props.closeMenu) props.closeMenu()
    dispatch(invalidateToken({ history }))
    document.location.href = '/'
  }

  return (
    <nav>
      <div
        data-testid="HeaderMenu"
        id="header-menu"
        className={cx(styles.headerMenu, props.open && styles.headerOpen)}
      >
        <div
          className={cx(
            styles.headerContainer,
            props.open && styles.headerContainerOpen
          )}
        >
          {/* eslint-disable-next-line array-callback-return */}
          {links.map((res, index) => {
            if (res.link === '/profile') {
              if (isLoggedIn)
                return (
                  <div
                    role="button"
                    tabIndex={links.length + index}
                    key={res.label}
                    className={cx(styles.menuRow)}
                    onClick={() => checkValidateBeforeRoute(res.link, true)}
                    onKeyDown={e => checkValidateBeforeRoute(res.link, true, e)}
                  >
                    {res.label}
                  </div>
                )
            } else if (res.link === '/signup') {
              if (!isLoggedIn)
                return (
                  <div
                    role="button"
                    tabIndex={links.length + index}
                    key={res.label}
                    className={cx(styles.menuRow)}
                    onClick={() => routeTo(res.link)}
                    onKeyDown={e => routeTo(res.link, e)}
                  >
                    {res.label}
                  </div>
                )
            } else if (res.label === 'Login') {
              if (!isLoggedIn)
                return (
                  <div
                    role="button"
                    tabIndex={links.length + index}
                    key={res.label}
                    className={cx(styles.menuRow)}
                    onClick={() => checkValidateBeforeRoute(res.link, true)}
                    onKeyDown={e => checkValidateBeforeRoute(res.link, true, e)}
                  >
                    {res.label}
                  </div>
                )
            } else
              return (
                <div
                  role="button"
                  tabIndex={links.length + index}
                  key={res.label}
                  className={cx(styles.menuRow)}
                  onClick={() => routeTo(res.link)}
                  onKeyDown={e => routeTo(res.link, e)}
                >
                  {res.label}
                </div>
              )
          })}
          {isLoggedIn && (
            <div
              role="button"
              tabIndex={links.length + 10}
              className={cx(styles.menuRow)}
              onClick={() => signout()}
              onKeyDown={e => {
                if (e.key === 'Enter' || e.key === ' ') signout()
              }}
            >
              Sign Out
            </div>
          )}
        </div>
      </div>
    </nav>
  )
}

HeaderMenu.propTypes = {
  /**
   * Show/hide header text
   */
  open: PropTypes.bool,
  closeMenu: PropTypes.func
}

export default HeaderMenu
