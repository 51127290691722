import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select, { createFilter } from 'react-select'

import FormLayout from 'layouts/FormLayout'
import BackButton from 'components/BackButton'
import { actions as optinActions } from 'modules/optin/OptinReducer'
import * as optinSelector from 'modules/optin/OptinSelector'
import { actions } from 'modules/user/UserReducer'
import * as userSelector from 'modules/user/UserSelector'
import * as authSelector from 'modules/auth/AuthSelector'
import { onMenuOpen } from '../../utils'
import {
  DropdownIndicator,
  IndicatorSeparator,
  SingleValue,
  style_form as style_select
} from 'components/Filter'
import {
  variables,
  fillInput,
  specialtyMap,
  checkPassword,
  checkField,
  checkSelect,
  handleSelectNotice,
  resetForm,
  checkError,
  checkIsSpecialtyRequired
} from './ProfileFunc'

import config from 'site.config'
import styles from './Profile.module.sass'

const { configuration } = config
const {
  getOptinProfession,
  getOptinCountry,
  getOptinSpecialtyCONTENT,
  getIsRequesting,
  getOriOptinSpecialtyCONTENT
} = optinSelector
const {
  getUserProfile,
  getIsFetchingUserProfile,
  getIsUpdatingProfile,
  getUpdateUserProfileFailed,
  getUpdateUserProfileSuccess
} = userSelector
const { getIsLogin } = authSelector

const recaptchaKey = configuration.envVars.REACT_APP_PHN_RECAPTCHA_KEY
function handleSubmit(submitForm) {
  window.grecaptcha.ready(() => {
    try {
      window.grecaptcha
        .execute(recaptchaKey, { action: 'profile' })
        .then(token => submitForm(token))
    } catch (e) {}
  })
}

const Profile = () => {
  const dispatch = useDispatch()
  const [focused, setFocused] = useState(false)
  const [country, setCountry] = useState({ label: 'Select', value: '' })
  const [professionId, setProfessionId] = useState({
    label: 'Select',
    value: ''
  })
  const [specialtyId, setSpecialtyId] = useState({ label: 'Select', value: '' })
  const [newPassword, setNewPassword] = useState('')
  const [errorFirstName, setErrorFirstName] = useState(' ')
  const [errorLastName, setErrorLastName] = useState(' ')
  const [errorPostalCode, setErrorPostalCode] = useState('')
  const [errorCountry, setErrorCountry] = useState('')
  const [errorProfession, setErrorProfession] = useState('')
  const [errorSpecialty, setErrorSpecialty] = useState('')
  const [errorPassword, setErrorPassword] = useState('')
  const isUserLoggedIn = useSelector(getIsLogin)
  const countryOptions = useSelector(getOptinCountry)
  const professionOptions = useSelector(getOptinProfession)
  const specialtyOptions = useSelector(getOptinSpecialtyCONTENT)
  const oriOptinSpecialtyCONTENT = useSelector(getOriOptinSpecialtyCONTENT)
  const profile = useSelector(getUserProfile)
  const isFetchingOptions = useSelector(getIsRequesting)
  const isFetchingUserProfile = useSelector(getIsFetchingUserProfile)
  const isUpdatingProfile = useSelector(getIsUpdatingProfile)
  const updateFailed = useSelector(getUpdateUserProfileFailed)
  const updateSuccess = useSelector(getUpdateUserProfileSuccess)
  const isLoading =
    isFetchingOptions || isFetchingUserProfile || isUpdatingProfile
  const recaptchaKey = configuration.envVars.REACT_APP_PHN_RECAPTCHA_KEY
  const physicianText = 'Physician'
  const residentText = 'Resident'

  useEffect(() => {
    if (isUserLoggedIn) {
      dispatch(optinActions.requestMetadata())
      dispatch(actions.requestUserProfile())
    }
  }, [dispatch, isUserLoggedIn])

  useEffect(() => {
    if (!isUpdatingProfile && document.getElementById('inputPassword')) {
      document.getElementById('inputPassword').value = ''
      document.getElementById('inputConfirmPassword').value = ''
    }
  }, [isUpdatingProfile])

  useEffect(() => {
    if (Object.keys(profile).length > 0) {
      // doesn't need to refresh the state value when isUpdatingProfile
      if (!isUpdatingProfile && !isLoading) {
        fillInput(
          profile,
          countryOptions,
          professionOptions,
          specialtyOptions,
          setCountry,
          setProfessionId,
          setSpecialtyId
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, isLoading])

  useEffect(() => {
    const recaptchaId = 'recaptcha-key'

    const loadScriptByURL = (id, url) => {
      const scriptExist = document.getElementById(id)
      if (!scriptExist) {
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = url
        script.id = id
        document.body.appendChild(script)
      }
    }

    // load the script by passing the URL
    try {
      loadScriptByURL(
        recaptchaId,
        `https://www.google.com/recaptcha/api.js?render=${recaptchaKey}`
      )

      return () => {
        document.getElementById(recaptchaId)?.remove()
      }
    } catch (e) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // handle dropdown change event
  const handleSelectChange = (input, elementId, fieldname, errorFunc) => {
    if (fieldname === 'Country') setCountry(input)
    if (fieldname === 'Profession') setProfessionId(input)
    if (fieldname === 'Specialty') setSpecialtyId(input)

    handleSelectNotice(input, elementId, fieldname, errorFunc)
  }

  const submitForm = token => {
    const {
      form,
      firstName,
      lastName,
      postalCode,
      password,
      confirmPassword,
      profession
    } = variables(professionOptions, professionId)

    try {
      if (!token || !form.checkValidity()) {
        checkError(
          country,
          setErrorFirstName,
          setErrorLastName,
          setErrorPostalCode,
          setErrorPassword,
          setErrorCountry
        )
        checkSelect(
          professionId,
          'inputProfession',
          'Profession',
          setErrorProfession
        )

        if (checkIsSpecialtyRequired(professionId)) {
          checkSelect(
            specialtyId,
            'inputSpecialty',
            'Specialty',
            setErrorSpecialty
          )
        } else setErrorSpecialty('')
        return
      }

      const data = {
        firstName,
        lastName,
        address: {
          country: country.value,
          countryCode: country.value2,
          postalCode
        },
        profession: {
          professionId: profession.value,
          professionCode: profession.label
        }
      }
      // use the same processing logic as the sign up.
      // specialtyMap returns null if the specialty is not found
      let chosenSpecialty = specialtyMap(
        professionId,
        specialtyId,
        specialtyOptions,
        oriOptinSpecialtyCONTENT
      )
      if (chosenSpecialty !== null) {
        data.specialty = chosenSpecialty
      }

      if (
        (professionId.label === physicianText ||
          professionId.label === residentText) &&
        !data.specialty
      ) {
        setErrorSpecialty('Your Specialty is required.')
        form.reportValidity()
        return false
      }

      if (password && password !== confirmPassword) {
        document
          .getElementById('inputPassword')
          .setCustomValidity('Confirm Password must be the same as Password')
        form.reportValidity()
        return false
      }
      if (password) {
        data.password = password
      }

      dispatch(actions.requestUpdateProfile(data))

      resetForm(
        setErrorFirstName,
        setErrorLastName,
        setErrorPostalCode,
        setErrorPassword,
        setErrorCountry,
        setErrorProfession,
        setErrorSpecialty
      )
    } catch (e) {}
  }

  return (
    <FormLayout loading={isLoading} disableLottie={true}>
      <div className={styles.box}>
        <h3>Edit Your Profile</h3>
        <form className={styles.form}>
          <div className={styles.passwordContainer}>
            <span>CHANGE PASSWORD</span>
            <div className={styles.fieldset}>
              <label htmlFor="inputPassword">Password</label>
              <input
                type="password"
                id="inputPassword"
                className={styles.focus}
                maxLength="20"
                minLength="6"
                pattern="[A-Za-z0-9$!$%^&_~\)\(*\-]{6,20}"
                disabled={isLoading}
                onChange={e => setNewPassword(e.target.value)}
                onFocus={() => {
                  setFocused(true)
                }}
                onBlur={e => {
                  setFocused(false)
                  checkPassword(e.target.value, setErrorPassword)
                }}
              />
              {focused ? (
                <span className={styles.helpertext}>
                  Minimum 6 alphanumeric characters
                </span>
              ) : (
                <div className={styles.err_span}>{errorPassword}</div>
              )}
            </div>
            <div className={styles.fieldset}>
              <label htmlFor="inputConfirmPassword">Confirm Password</label>
              <input
                type="password"
                id="inputConfirmPassword"
                className={styles.focus}
                maxLength="20"
                minLength="6"
                disabled={isLoading}
                required={!!newPassword}
                pattern={newPassword}
                onFocus={() => {
                  setErrorPassword('')
                }}
                onBlur={e => {
                  checkPassword(e.target.value, setErrorPassword)
                }}
              />
            </div>
          </div>
          <div className={styles.fieldset}>
            <label htmlFor="inputFirstName">
              First Name <span style={{ color: 'red' }}>*</span>
            </label>
            <input
              type="text"
              id="inputFirstName"
              className={styles.focus}
              required
              maxLength="60"
              disabled={isLoading}
              onBlur={() =>
                checkField('inputFirstName', 'First Name', setErrorFirstName)
              }
            />
            <div className={styles.err_span}>{errorFirstName}</div>
          </div>
          <div className={styles.fieldset}>
            <label htmlFor="inputLastName">
              Last Name <span style={{ color: 'red' }}>*</span>
            </label>
            <input
              type="text"
              id="inputLastName"
              className={styles.focus}
              required
              maxLength="60"
              disabled={isLoading}
              onBlur={() =>
                checkField('inputLastName', 'Last Name', setErrorLastName)
              }
            />
            <div className={styles.err_span}>{errorLastName}</div>
          </div>
          <div className={styles.fieldset}>
            <label htmlFor="inputPostalCode">
              Postal Code <span style={{ color: 'red' }}>*</span>
            </label>
            <input
              type="postalCode"
              id="inputPostalCode"
              className={styles.focus}
              required
              maxLength="60"
              disabled={isLoading}
              onBlur={() =>
                checkField('inputPostalCode', 'Postal Code', setErrorPostalCode)
              }
            />
            <div className={styles.err_span}>{errorPostalCode}</div>
          </div>
          <div className={styles.fieldset}>
            <label htmlFor="inputCountry">
              Country <span style={{ color: 'red' }}>*</span>
            </label>
            <Select
              className={styles.dropdown}
              classNamePrefix={'dropdown'}
              id={'inputCountry'}
              value={country}
              options={countryOptions.map(i => ({
                label: i.label,
                value: i.value,
                value2: i.value2
              }))}
              onChange={input =>
                handleSelectChange(
                  input,
                  'inputCountry',
                  'Country',
                  setErrorCountry
                )
              }
              onBlur={() =>
                checkSelect(country, 'inputCountry', 'Country', setErrorCountry)
              }
              onMenuOpen={() => onMenuOpen('dropdown')}
              filterOption={createFilter({ matchFrom: 'start' })}
              placeholder="Select"
              isSearchable={true}
              isClearable={false}
              isLoading={isLoading}
              components={{
                DropdownIndicator,
                IndicatorSeparator,
                SingleValue
              }}
              styles={style_select}
              zIndex={119}
              required
            />
            <div className={styles.err_span}>{errorCountry}</div>
          </div>
          <div className={styles.fieldset}>
            <label htmlFor="inputProfession">
              Profession <span style={{ color: 'red' }}>*</span>
            </label>
            <Select
              className={styles.dropdown}
              classNamePrefix={'dropdown'}
              id={'inputProfession'}
              value={professionId}
              options={professionOptions.map(i => ({
                label: i.label,
                value: i.value
              }))}
              onChange={input => {
                handleSelectChange(
                  input,
                  'inputProfession',
                  'Profession',
                  setErrorProfession
                )
                if (
                  input.label !== physicianText &&
                  input.label !== residentText
                )
                  setSpecialtyId({ label: 'Select', value: '' })
              }}
              onBlur={() =>
                checkSelect(
                  professionId,
                  'inputProfession',
                  'Profession',
                  setErrorProfession
                )
              }
              onMenuOpen={() => onMenuOpen('dropdown')}
              filterOption={createFilter({ matchFrom: 'start' })}
              placeholder="Select"
              isSearchable={true}
              isClearable={false}
              isLoading={isLoading}
              components={{
                DropdownIndicator,
                IndicatorSeparator,
                SingleValue
              }}
              styles={style_select}
              zIndex={119}
              required
            />
            <div className={styles.err_span}>{errorProfession}</div>
          </div>
          {professionId?.label !== 'Select' && (
            <div className={styles.fieldset}>
              <label htmlFor="inputSpecialty">
                Specialty{' '}
                {(professionId.label === physicianText ||
                  professionId.label === residentText) && (
                  <span style={{ color: 'red' }}>*</span>
                )}
              </label>
              <Select
                className={styles.dropdown}
                classNamePrefix={'dropdown'}
                id={'inputSpecialty'}
                value={specialtyId}
                options={specialtyOptions.map(i => ({
                  label: i.label,
                  value: i.value
                }))}
                onChange={input =>
                  handleSelectChange(
                    input,
                    'inputSpecialty',
                    'Specialty',
                    setErrorSpecialty
                  )
                }
                onBlur={() => {
                  if (checkIsSpecialtyRequired(professionId)) {
                    checkSelect(
                      specialtyId,
                      'inputSpecialty',
                      'Specialty',
                      setErrorSpecialty
                    )
                  }
                }}
                onMenuOpen={() => onMenuOpen('dropdown')}
                filterOption={createFilter({ matchFrom: 'start' })}
                placeholder="Select"
                isSearchable={true}
                isClearable={false}
                isLoading={isLoading}
                components={{
                  DropdownIndicator,
                  IndicatorSeparator,
                  SingleValue
                }}
                styles={style_select}
                zIndex={119}
                required={
                  professionId.label === physicianText ||
                  professionId.label === residentText
                }
              />
              <div className={styles.err_span}>{errorSpecialty}</div>
            </div>
          )}
          <div className={styles.fieldset}>
            <button
              onClick={e => {
                e.preventDefault()
                handleSubmit(submitForm)
              }}
              onKeyDown={e => {
                if (e.key === 'Enter' || e.key === ' ') {
                  handleSubmit(submitForm)
                }
              }}
              disabled={isLoading}
            >
              Save Changes
            </button>
          </div>
          <p style={{ color: '#444', fontSize: '14px' }}>
            <span style={{ color: 'red' }}>
              <b>*</b>
            </span>{' '}
            Required
          </p>
          {updateSuccess && (
            <div className={styles.submittedSuccessBox}>
              Your profile was updated successfully.
            </div>
          )}
          {updateFailed && (
            <div className={styles.submittedFailedBox}>
              An error occured, we were not able to proceed.
            </div>
          )}
        </form>

        <BackButton />
      </div>
    </FormLayout>
  )
}

export default Profile
