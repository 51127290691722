import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Modal from 'components/imported/Modal'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { useHistory } from 'react-router-dom'
import styles from './Auth.module.sass'
import LoginWithEmail from './AuthWithEmail'
import LoginWithPassword from './AuthWithPassword'
import LoginOtp from './AuthOtp'
import { actions } from './AuthReducer'
import * as AuthSelector from './AuthSelector'
import PageLoader from 'components/PageLoader'
import cx from 'classnames'
const { closeLoginModal } = actions
const {
  getShouldOpenModal,
  getWaitingOTPModal,
  getLoading,
  getIsVerifyingOtp
} = AuthSelector

const Auth = () => {
  const openModal = useSelector(getShouldOpenModal)
  const waitingOTP = useSelector(getWaitingOTPModal)
  const isLoading = useSelector(getLoading)
  const isOtpLoading = useSelector(getIsVerifyingOtp)
  const history = useHistory()
  const dispatch = useDispatch()
  const [useEmail, setUseEmail] = useState(true)
  const [usePassword, setUsePassword] = useState(false)
  const handleClose = path => {
    setUseEmail(true)
    setUsePassword(false)
    dispatch(closeLoginModal())
    history.replace(path || '/')
  }

  return (
    <>
      {isOtpLoading && <PageLoader />}
      <Modal
        modalOpen={openModal}
        style={{
          maxWidth: '535px',
          width: 'calc(100% - 10px)'
        }}
      >
        <div className={styles.loginModal}>
          <IconButton
            className={cx(
              styles.closeModal,
              isLoading && styles.disabledButton
            )}
            onClick={() => handleClose()}
            onKeyDown={e => {
              if (e.key === 'Enter' || e.key === ' ') handleClose()
            }}
            disabled={isLoading}
            data-testid="closeBtn"
          >
            <CloseIcon
              sx={{ height: '15px', width: '15px', color: '#3b78c3' }}
            />
          </IconButton>
          <div style={{ height: '6px' }}></div>
          {!waitingOTP && useEmail && <LoginWithEmail />}
          {waitingOTP && useEmail && <LoginOtp />}
          {!usePassword ? (
            !isLoading && (
              <button
                className={styles.loginPasswordLink}
                onClick={() => {
                  setUsePassword(true)
                  setUseEmail(false)
                }}
                onKeyDown={e => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    setUsePassword(true)
                    setUseEmail(false)
                  }
                }}
              >
                Log In Using Password
              </button>
            )
          ) : (
            <>
              <LoginWithPassword />
              {!isLoading && (
                <button
                  className={styles.loginPasswordLink}
                  onClick={() => {
                    setUseEmail(true)
                    setUsePassword(false)
                  }}
                >
                  Log In Using Email
                </button>
              )}
            </>
          )}
          <div className={styles.dividerline}></div>
          <div className={styles.loginCaption2Text}>
            Don’t have an account with
            <br />
            Answers in CME?
          </div>
          <button
            className={cx(styles.loginBtn2, isLoading && styles.disableInput)}
            onClick={() => handleClose('/signup')}
            onKeyDown={e => {
              if (e.key === 'Enter' || e.key === ' ') handleClose('/signup')
            }}
          >
            Create an Account
          </button>
        </div>
      </Modal>
    </>
  )
}

export default Auth
