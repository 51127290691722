import { useDispatch } from 'react-redux'
import styles from './AuthWithEmail.module.sass'
import { useHistory } from 'react-router-dom'
import { actions } from './AuthReducer'

const { closeLoginModal } = actions
const AuthOtp = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const routeTo = path => {
    dispatch(closeLoginModal())
    history.replace(path)
  }
  return (
    <div className={styles.container}>
      <h1>Log In with Email Only</h1>
      <div className={styles.loginCaptionText}>
        You have been sent an email with a log-in link. Please check your inbox
        and click on the link in the email.
      </div>
      <div className={styles.loginCaptionText}>
        If you did not receive an email or are unable to log in, please{' '}
        <span
          role="button"
          tabIndex="0"
          className={styles.loginPasswordLink}
          onClick={() => routeTo('/contactus')}
          onKeyDown={e => {
            if (e.key === 'Enter' || e.key === ' ') routeTo('/contactus')
          }}
        >
          contact us.
        </span>
      </div>
    </div>
  )
}
export default AuthOtp
