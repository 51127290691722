import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import PropTypes from 'prop-types'

import Header from 'components/Header'
import Footer from 'components/Footer'
import BackButton from 'components/BackButton'
import PageLoader from 'components/PageLoader'
import SearchResult from 'components/SearchResult'
import styles from './StaticFileLayout.module.sass'
import { getPrivacySectionConfig } from 'modules/bootstrap/BootstrapSelector'
import { getSearchTerm } from 'modules/home/HomeSelector'

import config from 'site.config'

const { sections, configuration } = config

const crypto = window.crypto || window.msCrypto
let random = new Uint32Array(1)

const StaticFileLayout = ({ title, filename }) => {
  const searchTerm = useSelector(getSearchTerm)
  const [menuOpen, setMenuOpen] = useState(false)

  let [loading, setLoading] = useState(true)
  let [html, setHTML] = useState(`<h1>Loading ${title}...</h1>`)
  let [serviceName, setServiceName] = useState(sections.privacy.service_name)
  let [brandName, setBrandName] = useState(sections.privacy.brand_name)
  let [address, setAddress] = useState(sections.privacy.address)
  let [email, setEmail] = useState(sections.privacy.email)

  const privacy_section = useSelector(getPrivacySectionConfig)
  useEffect(() => {
    setServiceName(
      privacy_section?.service_name || sections.privacy.service_name
    )
    setBrandName(privacy_section?.brand_name || sections.privacy.brand_name)
    setAddress(privacy_section?.address || sections.privacy.address)
    setEmail(privacy_section?.email || sections.privacy.email)
  }, [privacy_section])

  const baseUrl =
    process.env.NODE_ENV !== 'production'
      ? window.location.origin + '/cdn'
      : `${configuration?.envVars?.REACT_APP_CDN_URL}/${configuration.assetPath}`

  // Load HTML file
  useEffect(() => {
    axios.get(`${baseUrl}/${filename}.html`).then(
      ({ data }) => {
        data = data
          .replaceAll('{service_name}', serviceName)
          .replaceAll('{brand_name}', brandName)
          .replaceAll('{address}', address)
          .replaceAll('{email}', email)
        setHTML(data)
        // Load JS only after HTML is loaded
        const script = document.createElement('script')
        script.src = `${baseUrl}/${filename}.js`
        script.async = true
        document.body.appendChild(script)
        setLoading(false)
      },
      err => {
        setHTML(`<h1>Failed to load ${title}!</h1>`)
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceName, brandName, address, email])

  // Load CSS file
  useEffect(() => {
    const link = document.createElement('link')
    link.href = `${baseUrl}/${filename}.css`
    link.setAttribute('rel', 'stylesheet')
    document.head.appendChild(link)
    return () => document.head.removeChild(link)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={styles.box}>
      <Header menuOpen={menuOpen} />
      <div className={styles.gap}></div>
      {!searchTerm?.length && (
        <div
          role="button"
          tabIndex={0}
          data-testid="content"
          className={styles.content}
          onClick={() => setMenuOpen(crypto?.getRandomValues(random)[0])}
          onKeyDown={e => {
            if (e.key === 'Enter' || e.key === ' ')
              setMenuOpen(crypto?.getRandomValues(random)[0])
          }}
        >
          <div dangerouslySetInnerHTML={{ __html: html }} />
          <BackButton />
        </div>
      )}
      {searchTerm?.length > 0 && (
        <div
          role="button"
          tabIndex={0}
          data-testid="search"
          className={styles.content}
          onClick={() => setMenuOpen(crypto?.getRandomValues(random)[0])}
          onKeyDown={e => {
            if (e.key === 'Enter' || e.key === ' ')
              setMenuOpen(crypto?.getRandomValues(random)[0])
          }}
        >
          <SearchResult />
        </div>
      )}
      {loading && <PageLoader />}
      <Footer />
    </div>
  )
}

StaticFileLayout.propTypes = {
  title: PropTypes.string,
  filename: PropTypes.string
}
export default StaticFileLayout
