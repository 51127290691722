import { LazyLoadImage } from 'react-lazy-load-image-component'
import PropTypes from 'prop-types'
import styles from './InterestCard.module.sass'

const InterestCard = ({
  thumbnail,
  credit,
  creditType,
  title,
  description,
  url,
  alt
}) => {
  const isHTML = description?.startsWith('<p>')

  return (
    <div className={styles.card}>
      <div>
        <a href={url} target="_blank" rel="noreferrer">
          <LazyLoadImage
            src={thumbnail}
            className={styles.img}
            effect="opacity"
            alt={alt}
          />
        </a>
      </div>
      <div className={styles.detail}>
        <p className={styles.credit}>{credit + ' ' + creditType}</p>
        <a
          dangerouslySetInnerHTML={{ __html: title }}
          href={url}
          target="_blank"
          rel="noreferrer"
          className={styles.title}
        />
        {!isHTML && (
          <p className={styles.description}>
            {description ? description + '...' : ''}
            <a
              href={url}
              target="_blank"
              rel="noreferrer"
              className={styles.readmore}
            >
              read more
            </a>
          </p>
        )}
        {isHTML && (
          <span
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html:
                description.substring(-3, description.length - 4) +
                '...' +
                `<a href="` +
                url +
                `" style="margin-left:5px;" class="readmoreLink" target="_blank" rel="noreferrer">read more</a>` +
                '</p>'
            }}
          />
        )}
      </div>
    </div>
  )
}

InterestCard.propTypes = {
  thumbnail: PropTypes.string,
  credit: PropTypes.string,
  creditType: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  url: PropTypes.string,
  alt: PropTypes.string
}

export default InterestCard
