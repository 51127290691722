import { takeLeading, all, put, call } from 'redux-saga/effects'
import { actions } from 'modules/optin/OptinReducer'
// import { actions as notificationActions } from 'modules/notification/NotificationReducer'
import {
  getOptinSpecialtyById,
  getProfileSpecialtyById
} from 'modules/specialty-mapping/SpecialtyMapping'
import config from 'site.config'

const {
  configuration: { envVars }
} = config

// const showNotification = false

export function* initialize(services) {
  try {
    const sessionService = services('SessionService')
    const sessionMetadata = sessionService.getFromCache('optin_metadata', null)
    if (sessionMetadata !== null) {
      yield put(actions.successRequest(sessionMetadata))
    } else {
      const metadata = yield call(getMetadata, services)
      sessionService.saveToCache('optin_metadata', metadata)
      yield put(actions.successRequest(metadata))
    }
  } catch (error) {
    // if (showNotification && process.env.NODE_ENV !== 'production') {
    //   yield put(
    //     notificationActions.notificationEnqueue({
    //       message: 'Fail to request metadata.',
    //       duration: 0
    //     })
    //   )
    // }
  }
}

export function applyFilters(
  country,
  profession,
  specialty,
  specialtyMap,
  specialtyActive
) {
  const optionCountry = country?.responseData?.referenceTable?.data.map(
    country => ({
      value: country.value,
      value2: country.value2,
      label: country.value
    })
  )
  const optionProfession = profession?.responseData?.referenceTable?.data
    .filter(prof => prof.value3 === 'LIMA')
    .map(prof => ({ value: prof.value2, label: prof.value }))
    .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()))
  // this specialty is for profile form
  const optionSpecialty = specialty?.responseData?.referenceTable?.data
    .filter(spec => spec.value2 === 'LIMA')
    .map(spec => ({ value: spec.key, label: spec.value }))
    .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()))
  // this specialty is for profile form, atm, only CONTENT will be used
  const optionSpecialtyCONTENT = specialty?.responseData?.referenceTable?.data
    .filter(spec => spec.value2 === 'CONTENT')
    .map(
      spec =>
        getProfileSpecialtyById(spec.key) || {
          value: spec.key,
          label: spec.value
        }
    )
    .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()))
  const originalSpecialtyCONTENT = specialty?.responseData?.referenceTable?.data
    .filter(spec => spec.value2 === 'CONTENT')
    .map(spec => ({ value: spec.key, label: spec.value }))
    .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()))
  // map is for optin, atm, only CONTENT will be used
  const mapSpecialtyCONTENT = specialtyMap?.responseData?.referenceTable?.data
    .filter(spec => spec.relationshiptype === '1_lima_to_1_content')
    .map(
      spec =>
        getOptinSpecialtyById(spec.contentspecialtyid) || {
          value: spec.contentspecialtyid,
          label: spec.contentspecialtycode
        }
    )
    .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()))
  // keep a copy of the original response data
  const originalMapSpecialtyCONTENT = specialtyMap?.responseData?.referenceTable?.data
    .filter(spec => spec.relationshiptype === '1_lima_to_1_content')
    .map(spec => ({
      value: spec.contentspecialtyid,
      label: spec.contentspecialtycode
    }))
    .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()))
  // map is for optin
  const mapSpecialty = specialtyMap?.responseData?.referenceTable?.data
    .filter(spec => spec.relationshiptype === '1_lima_to_1_content')
    .map(spec => ({
      value: spec.limaspecialtyid,
      label: spec.limaspecialtycode
    }))
    .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()))

  // this specialty is for header specialty dropdown
  // const LIMAtoCONTENT = specialtyMap?.responseData?.referenceTable?.data.filter(
  //   spec => spec.relationshiptype === '1_lima_to_m_content'
  // )
  // const optionSpecialtyLIMAtoCONTENT = specialty?.responseData?.referenceTable?.data
  //   .filter(spec => spec.value2 === 'LIMA' && spec.value !== 'Other')
  //   .map(spec => ({
  //     value: spec.key,
  //     label: spec.value,
  //     value2:
  //       LIMAtoCONTENT.find(f => f.limaspecialtyid === spec.key)
  //         ?.specialtyalias || spec.value
  //   }))
  //   .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()))
  const activeSpecialty = JSON.parse(specialtyActive?.specialty_list || '[]')
  const optionSpecialtyLIMAtoCONTENT = activeSpecialty
    .map(spec => ({
      value: spec.value,
      label: spec.label,
      value2: spec.feedSpecialty
    }))
    .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()))

  return {
    optionCountry,
    optionProfession,
    optionSpecialty,
    optionSpecialtyLIMAtoCONTENT,
    optionSpecialtyCONTENT,
    originalSpecialtyCONTENT,
    mapSpecialtyCONTENT,
    originalMapSpecialtyCONTENT,
    mapSpecialty
  }
}

export function* getMetadata(services) {
  const cookieService = services('CookieService')
  const apiService = services('APIService')

  const baseUrl = envVars?.REACT_APP_PHNX_API ?? process.env.REACT_APP_PHNX_API
  const nemlUrl = envVars?.REACT_APP_NEML_API ?? process.env.REACT_APP_NEML_API

  // parse the auth token from Cookie
  const authToken = JSON.parse(cookieService.get('authToken'))

  // additional header with bearer token
  const additionalHeader = {
    Authorization: `Bearer ${authToken.access}`
  }
  const country = yield call(
    [apiService, 'getWithHeaders'],
    baseUrl + '/v2/reference/country',
    {},
    additionalHeader
  )
  const profession = yield call(
    [apiService, 'getWithHeaders'],
    baseUrl + '/v2/reference/profession',
    {},
    additionalHeader
  )
  const specialty = yield call(
    [apiService, 'getWithHeaders'],
    baseUrl + '/v2/reference/specialty',
    {},
    additionalHeader
  )
  const specialtyMap = yield call(
    [apiService, 'getWithHeaders'],
    baseUrl + '/v2/reference/specialtyMap',
    {},
    additionalHeader
  )
  const specialtyActive = yield call(
    [apiService, 'getWithoutAnyHeaders'],
    nemlUrl + '/toolbox/v1/specialties-with-articles'
  )

  const {
    optionCountry,
    optionProfession,
    optionSpecialty,
    optionSpecialtyLIMAtoCONTENT,
    optionSpecialtyCONTENT,
    originalSpecialtyCONTENT,
    mapSpecialtyCONTENT,
    originalMapSpecialtyCONTENT,
    mapSpecialty
  } = applyFilters(
    country,
    profession,
    specialty,
    specialtyMap,
    specialtyActive
  )

  return {
    optionCountry: optionCountry || [],
    optionProfession: optionProfession || [],
    optionSpecialty: optionSpecialty || [],
    optionSpecialtyLIMAtoCONTENT: optionSpecialtyLIMAtoCONTENT || [],
    optionSpecialtyCONTENT: optionSpecialtyCONTENT || [],
    optionMapSpecialtyCONTENT: mapSpecialtyCONTENT || [],
    optionMapSpecialty: mapSpecialty || [],
    originalMapSpecialtyContent: originalMapSpecialtyCONTENT || [],
    originalSpecialtyCONTENT: originalSpecialtyCONTENT || []
  }
}

export default function* watchOptin(services) {
  yield all([takeLeading(actions.requestMetadata().type, initialize, services)])
}
