import { useSelector } from 'react-redux'

import LoadingDots from 'components/LoadingDots'
import { getLoadingPodcasts, getPodcasts } from 'modules/home/HomeSelector'
import styles from './Curriculum.module.sass'

const Podcast = () => {
  const loading = useSelector(getLoadingPodcasts)
  const podcast = useSelector(getPodcasts)

  return (
    <div className={styles.container}>
      <h2 className={styles.h2}>Subscribe to Our Podcasts</h2>
      {!loading &&
        podcast?.map(({ channel, distributions }) => (
          <div key={channel} className={styles.span}>
            <p className={styles.menuText}>{channel}</p>
            {distributions
              .map(({ name, url }) => (
                <a
                  key={name}
                  href={url}
                  target="_blank"
                  rel="noreferrer"
                  className={styles.link}
                >
                  {name}
                </a>
              ))
              .reduce((prev, curr) => [prev, ' | ', curr])}
          </div>
        ))}
      {loading && <LoadingDots />}
    </div>
  )
}

Podcast.propTypes = {}

export default Podcast
