import { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styles from './AuthWithEmail.module.sass'
import { actions } from './AuthReducer'
import * as AuthSelector from './AuthSelector'
import { CircularProgress } from 'components/imported/Progress'
import { variables, checkField, checkError } from './AuthFunc'

const { loginWithPasswordRequest } = actions
const { getLoading, getLoginErrMsg } = AuthSelector

const AuthWithPassword = () => {
  const dispatch = useDispatch()
  const refEmail = useRef()
  const refPassword = useRef()
  const history = useHistory()
  const isLoading = useSelector(getLoading)
  const loginErrMsg = useSelector(getLoginErrMsg)
  const [errorEmail, setErrorEmail] = useState(' ')
  const [errorPassword, setErrorPassword] = useState(' ')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const submitForm = () => {
    let { form, email, password } = variables()
    if (email && password && form.checkValidity()) {
      if (refEmail.current.value && refPassword.current.value) {
        dispatch(
          loginWithPasswordRequest({
            email: refEmail.current.value,
            password: refPassword.current.value,
            history: history
          })
        )
      }
    } else {
      checkError(setErrorEmail, setErrorPassword)
    }
  }

  return (
    <div className={styles.container}>
      <h1>Enter Your Email and Password to Log In</h1>
      <div className={styles.loginErrMsg}>{loginErrMsg}</div>
      <form className={styles.form}>
        <div className={styles.group}>
          <label htmlFor="email">
            Email <span style={{ color: 'red' }}>*</span>
          </label>
          <input
            id="email"
            name="email"
            type="email"
            alt="email"
            value={email}
            required
            ref={refEmail}
            disabled={isLoading}
            onChange={e => setEmail(e.target.value)}
            onBlur={() => checkField('email', 'Email', setErrorEmail)}
          />
          <div className={styles.err_span}>{errorEmail}</div>
        </div>
        <div className={styles.group}>
          <label htmlFor="password">
            Password <span style={{ color: 'red' }}>*</span>
          </label>
          <input
            id="password"
            name="password"
            type="password"
            alt="password"
            value={password}
            required
            ref={refPassword}
            disabled={isLoading}
            onChange={e => setPassword(e.target.value)}
            onBlur={() => checkField('password', 'Password', setErrorPassword)}
          />
          <div className={styles.err_span}>{errorPassword}</div>
        </div>
        {!isLoading ? (
          <button
            type="button"
            onClick={() => submitForm()}
            onKeyDown={e => {
              if (e.key === 'Enter' || e.key === ' ') submitForm()
            }}
            disabled={isLoading}
          >
            Log In
          </button>
        ) : (
          <CircularProgress
            strokeColor="#3b78c3"
            strokeBackgroundColor="#CECECE"
            fillColor="transparent"
            className={styles.circularProgress}
            size={38}
          />
        )}
        <p style={{ color: '#444', fontSize: '14px', marginTop: '20px' }}>
          <span style={{ color: 'red' }}>
            <b>*</b>
          </span>{' '}
          Required
        </p>
      </form>
    </div>
  )
}
export default AuthWithPassword
