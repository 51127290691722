import PropTypes from 'prop-types'
import styles from './Curriculum.module.sass'

const EventCard = ({
  title,
  url,
  dates,
  format,
  location,
  credit,
  presenters
}) => {
  return (
    <div key={title} className={styles.span}>
      <a href={url} target="_blank" rel="noreferrer">
        <p
          dangerouslySetInnerHTML={{ __html: title }}
          className={styles.link}
          style={{ fontWeight: 'bold' }}
        />
      </a>
      <table>
        {dates && (
          <tr style={{ display: 'block', marginBottom: '7px' }}>
            <td style={{ verticalAlign: 'baseline', width: '100px' }}>
              <p className={styles.info}>
                <b>Date:</b>
              </p>
            </td>
            <td>
              <p className={styles.info}>{dates.join(`\n`)}</p>
            </td>
          </tr>
        )}
        {location && (
          <tr style={{ display: 'block', marginBottom: '7px' }}>
            <td style={{ verticalAlign: 'baseline', width: '100px' }}>
              <p className={styles.info}>
                <b>Location:</b>
              </p>
            </td>
            <td>
              <p className={styles.info}>{location}</p>
            </td>
          </tr>
        )}
        {credit && (
          <tr style={{ display: 'block', marginBottom: '7px' }}>
            <td style={{ verticalAlign: 'baseline', width: '100px' }}>
              <p className={styles.info}>
                <b>Credit:</b>
              </p>
            </td>
            <td>
              <p className={styles.info}>{credit}</p>
            </td>
          </tr>
        )}
        {presenters && (
          <tr style={{ display: 'block', marginBottom: '7px' }}>
            <td style={{ verticalAlign: 'baseline', width: '100px' }}>
              <p className={styles.info}>
                <b>Presenters:</b>
              </p>
            </td>
            <td>
              <p className={styles.info}>{presenters.join(`\n`)}</p>
            </td>
          </tr>
        )}
      </table>
    </div>
  )
}
EventCard.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  dates: PropTypes.array,
  format: PropTypes.string,
  location: PropTypes.string,
  credit: PropTypes.string,
  presenters: PropTypes.array
}

export default EventCard
