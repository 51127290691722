import { components } from 'react-select'
import PropTypes from 'prop-types'
import Svg from 'react-inlinesvg'

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <Svg src="/img/unfold_more.svg" width={14} height={20} fill={'#3b78c3'} />
    </components.DropdownIndicator>
  )
}

const IndicatorSeparator = ({ innerProps }) => {
  return (
    <span
      style={{
        alignSelf: 'stretch',
        width: 0
      }}
      {...innerProps}
    />
  )
}
IndicatorSeparator.propTypes = {
  innerProps: PropTypes.any
}

const SingleValue = ({ children, ...props }) => (
  <components.SingleValue {...props}>{children}</components.SingleValue>
)
SingleValue.propTypes = {
  children: PropTypes.any
}

const style_select = {
  singleValue: base => ({
    ...base,
    color: '#6E6E6E'
  }),
  valueContainer: base => ({
    ...base,
    padding: '2px 0 2px 10px'
  }),
  menu: base => ({
    ...base,
    marginTop: 0,
    borderRadius: '0 0 5px 5px'
  }),
  menuList: base => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#707070'
  }),
  control: base => ({
    ...base,
    cursor: 'pointer',
    borderRadius: '5px',
    height: '40px'
  }),
  placeholder: base => ({
    ...base,
    color: '#08487d',
    fontFamily: 'PT Sans',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '24px',
    marginLeft: '5px',
    textWrap: 'nowrap'
  }),
  indicatorContainer: base => ({
    ...base,
    padding: '12px 12px 10px 12px'
  })
}

const style_form = {
  ...style_select,
  singleValue: base => ({
    ...base,
    color: '#6E6E6E'
  }),
  valueContainer: base => ({
    ...base,
    padding: '0 0 0 8px'
  }),
  input: base => ({
    ...base,
    padding: '0 0',
    margin: '0 2px'
  }),
  menuList: base => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
    height: '140px'
  })
}

const style_contact = {
  ...style_select,
  valueContainer: base => ({
    ...base,
    padding: '0 0 0 20px'
  }),
  inputContainer: base => ({
    ...base,
    padding: '0 0'
  }),
  input: base => ({
    ...base,
    padding: '0 0',
    margin: '0 0'
  }),
  indicatorContainer: base => ({
    ...base,
    padding: '15px 20px'
  }),
  menu: base => ({
    ...base,
    marginTop: 0,
    borderRadius: '0 0 5px 5px'
  }),
  control: (base, { isDisabled }) => ({
    ...base,
    cursor: 'pointer',
    borderRadius: '5px',
    height: '50px',
    backgroundColor: isDisabled ? 'rgba(239, 239, 239, 0.3)' : '#fff',
    borderColor: isDisabled ? 'rgba(118, 118, 118, 0.3)' : 'rgb(118, 118, 118)'
  })
}

export {
  DropdownIndicator,
  IndicatorSeparator,
  SingleValue,
  // IconOption,
  style_select,
  style_form,
  style_contact
}
