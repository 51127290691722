import PropTypes from 'prop-types'
import StaticFileLayout from 'layouts/StaticFileLayout'

const Static = ({ title, filename }) => {
  return (
    <StaticFileLayout
      title={title}
      filename={filename}
      key={window.location.pathname}
    />
  )
}

Static.propTypes = {
  title: PropTypes.string,
  filename: PropTypes.string
}

export default Static
