import { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styles from './AuthWithEmail.module.sass'
import { actions } from './AuthReducer'
import * as AuthSelector from './AuthSelector'
import { CircularProgress } from 'components/imported/Progress'
import { variables, checkField, resetForm, checkError } from './AuthFunc'

const { loginWithEmailRequest } = actions
const { getLoading } = AuthSelector

const AuthWithEmail = () => {
  const dispatch = useDispatch()
  const ref = useRef()
  const isLoading = useSelector(getLoading)
  const [email, setEmail] = useState('')
  const [errorEmail, setErrorEmail] = useState(' ')

  const submitForm = () => {
    let { form, email } = variables()
    if (email && form.checkValidity()) {
      dispatch(loginWithEmailRequest({ email: ref.current.value }))
      resetForm()
    } else {
      checkError(setErrorEmail)
    }
  }

  return (
    <div className={styles.container}>
      <h1>Login with Your Email</h1>
      <form className={styles.form}>
        <div className={styles.group}>
          <label htmlFor="email">
            Email <span style={{ color: 'red' }}>*</span>
          </label>
          <input
            id="email"
            name="email"
            type="email"
            alt="email"
            value={email}
            required
            ref={ref}
            disabled={isLoading}
            onChange={e => setEmail(e.target.value)}
            onBlur={() => checkField('email', 'Email', setErrorEmail)}
          />
          <div className={styles.err_span}>{errorEmail}</div>
        </div>
        {!isLoading ? (
          <button
            type="button"
            onClick={() => submitForm()}
            onKeyDown={e => {
              if (e.key === 'Enter' || e.key === ' ') submitForm()
            }}
            disabled={isLoading}
          >
            Enter
          </button>
        ) : (
          <CircularProgress
            strokeColor="#3b78c3"
            strokeBackgroundColor="#CECECE"
            fillColor="transparent"
            className={styles.circularProgress}
            size={38}
          />
        )}
        <p style={{ color: '#444', fontSize: '14px', marginTop: '20px' }}>
          <span style={{ color: 'red' }}>
            <b>*</b>
          </span>{' '}
          Required
        </p>
      </form>
      <div className={styles.loginCaptionText}>
        A log-in link will be sent to the email provided above.
      </div>
    </div>
  )
}
export default AuthWithEmail
